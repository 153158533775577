<template>
  <v-card :loading="isLoading">
    <v-card-title>
      <h3 class="card-label">
        <div v-if="originalData.name">
          <small>Yönetim Şirketi</small><br />
          <span>{{ originalData.name }}</span>
        </div>
        <span v-else>Yönetim Şirketi</span>
      </h3>
      <v-col class="text-right p-0">
        <rs-action
          :to="{ name: 'definitions.companies.edit', params: { id: id } }"
          v-if="id && can('edit-company') && disabled"
          icon
          title="Düzenle"
        >
          <v-icon>mdi-pencil</v-icon>
        </rs-action>

        <rs-action
          @click="showActionLogs"
          v-if="id && can('edit-company')"
          icon
          title="Düzenleme geçmişi"
        >
          <v-icon>mdi-information-variant</v-icon>
        </rs-action>
      </v-col>
    </v-card-title>
    <v-card-subtitle v-if="mode === 'admin'">
      <div class="d-block w-100 mb-2">
        <rs-return-to />
      </div>
    </v-card-subtitle>
    <v-card-text v-if="idComputed || mode === 'admin'">
      <v-tabs v-model="selectedTab">
        <v-tab to="#info" replace>Bilgiler</v-tab>
        <v-tab to="#clusters" replace :disabled="!idComputed">TYA'lar</v-tab>
        <v-tab
          to="#workers"
          replace
          :disabled="!idComputed"
          v-if="mode === 'admin'"
        >
          Çalışanlar
        </v-tab>
        <v-tab
          to="#providers"
          replace
          :disabled="!idComputed"
          v-if="false && mode === 'admin'"
        >
          Tedarikçiler
        </v-tab>
        <v-tab to="#notes" replace :disabled="!idComputed">
          {{ $t("headers.notes_and_reminders") }}
        </v-tab>
        <v-tab to="#documents" replace :disabled="!idComputed">
          {{ $t("headers.documents") }}
        </v-tab>
        <v-tab
          to="#other"
          replace
          :disabled="!idComputed"
          v-if="can('superadministrator')"
        >
          Diğer
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="selectedTab">
        <v-tab-item value="info" class="pa-4">
          <v-form @submit.prevent="handleFormSubmit" ref="form">
            <v-row dense>
              <v-col sm="6">
                <rs-text-field
                  :label="$t('labels.company_title')"
                  :rules="[
                    rules.required,
                    rules.maxLength(formData.title, 300),
                  ]"
                  v-model="formData.title"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>

              <v-col sm="6">
                <rs-text-field
                  :label="$t('labels.company_name')"
                  :rules="[rules.required, rules.maxLength(formData.name, 120)]"
                  v-model="formData.name"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col sm="6">
                <rs-select-location
                  :label="$t('labels.city')"
                  :rules="[rules.required]"
                  v-model="formData.city_id"
                  :readonly="disabled"
                  :filled="disabled"
                  :location-type-id="2"
                  required
                />
              </v-col>

              <v-col sm="6">
                <rs-select-location
                  :label="$t('labels.district')"
                  :rules="[rules.required]"
                  v-model="formData.district_id"
                  :readonly="disabled"
                  :filled="disabled"
                  :location-type-id="3"
                  :parent-id="formData.city_id"
                  required
                  :disabled="!formData.city_id"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col sm="6">
                <rs-text-field
                  :label="$t('labels.tax_office')"
                  :rules="[
                    rules.required,
                    rules.maxLength(formData.tax_office, 20),
                  ]"
                  v-model="formData.tax_office"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>

              <v-col sm="6">
                <rs-text-field
                  :label="$t('labels.tax_number')"
                  :rules="[
                    rules.required,
                    rules.maxLength(formData.tax_number, 20),
                  ]"
                  v-model="formData.tax_number"
                  :readonly="disabled || !hasRole('superadministrator')"
                  :filled="disabled || !hasRole('superadministrator')"
                />
              </v-col>
            </v-row>
            <v-row dense v-if="!idComputed">
              <v-col md="4">
                <rs-text-field
                  :label="$t('labels.authorized_person')"
                  :rules="[
                    rules.required,
                    rules.maxLength(formData.owner_name, 200),
                  ]"
                  v-model="formData.owner_name"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>
              <v-col md="4">
                <rs-text-field
                  type="email"
                  :label="$t('labels.authorized_person_email')"
                  :rules="[
                    rules.required,
                    rules.email,
                    rules.maxLength(formData.owner_email, 200),
                  ]"
                  v-model="formData.owner_email"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>
              <v-col md="4">
                <rs-tel-field
                  :label="$t('labels.authorized_person_phone')"
                  :rules="[
                    rules.required,
                    formData.owner_phone != ''
                      ? rules.minLength(formData.owner_phone, 7)
                      : null,
                    rules.numbersOnlyWithParam,
                  ]"
                  v-model="formData.owner_phone"
                  :disabled="disabled"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col sm="6" md="3">
                <rs-tel-field
                  :label="$t('labels.company_phone')"
                  v-model="formData.phone"
                  :disabled="disabled"
                  :rules="[
                    formData.phone != ''
                      ? rules.minLength(formData.phone, 7)
                      : null,
                    rules.numbersOnlyWithParam,
                  ]"
                />
              </v-col>

              <v-col sm="6" md="3">
                <rs-text-field
                  :label="$t('labels.company_email')"
                  :rules="[rules.email, rules.maxLength(formData.email, 120)]"
                  v-model="formData.email"
                  type="email"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>

              <v-col sm="6" md="3">
                <rs-tel-field
                  :label="$t('labels.fax')"
                  v-model="formData.fax"
                  :disabled="disabled"
                  :rules="[
                    formData.fax != ''
                      ? rules.minLength(formData.fax, 7)
                      : null,
                    rules.numbersOnlyWithParam,
                  ]"
                />
              </v-col>

              <v-col sm="6" md="3">
                <rs-text-field
                  :label="$t('labels.website')"
                  :rules="[rules.maxLength(formData.website, 500)]"
                  v-model="formData.website"
                  type="url"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col sm="6">
                <rs-textarea
                  :label="$t('labels.company_address')"
                  :rules="[
                    rules.required,
                    rules.maxLength(formData.address, 500),
                  ]"
                  v-model="formData.address"
                  rows="3"
                  no-resize
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>
              <v-col sm="6">
                <rs-textarea
                  :label="$t('labels.billing_address')"
                  :rules="[rules.maxLength(formData.address_legal, 500)]"
                  v-model="formData.address_legal"
                  rows="3"
                  no-resize
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col sm="6">
                <rs-file
                  :label="$t('labels.logo')"
                  :rules="[]"
                  accept="image/png, image/jpeg"
                  :disabled="disabled"
                  :filled="disabled"
                  @change="handleLogoChange"
                  hint="En fazla 3MB. JPG veya PNG."
                />
              </v-col>
              <v-col sm="6">
                <div v-if="formData.logo">
                  <img :src="formData.logo.url" class="report-logo" />

                  <v-btn
                    color="pink"
                    small
                    outlined
                    dark
                    class="ms-3"
                    @click="handleRemoveLogoClick"
                  >
                    Kaldır
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="6">
                <v-switch
                  v-model="formData.is_active"
                  :label="formData.is_active ? $t('active') : $t('passive')"
                  hide-details="auto"
                  :readonly="disabled || !hasRole('superadministrator')"
                  :filled="disabled || !hasRole('superadministrator')"
                />
              </v-col>
            </v-row>

            <rs-form-buttons
              :hide-cancel="mode === 'myCompany'"
              :hide-save-close="mode === 'myCompany'"
              :is-loading="isLoading"
              @cancel="handleCancelClick"
              @submit="handleFormSubmit"
              v-if="!disabled"
            />
          </v-form>
          <ActionLogList ref="actionLogList" />
        </v-tab-item>
        <v-tab-item value="clusters" class="pa-4">
          <CompanyClusterList :company-id="idComputed" :mode="mode" />
        </v-tab-item>
        <v-tab-item value="workers" class="pa-4">
          <CompanyWorkerList
            :company-id="id"
            :show-edit="can('edit-company-user')"
            :show-search="false"
            :show-header="true"
          />
        </v-tab-item>
        <v-tab-item value="providers" class="pa-4" v-if="false">
          <ProviderList :company-id="idComputed" />
        </v-tab-item>
        <v-tab-item value="notes" class="pa-4">
          <!-- TODO: the notes should be separate based on "mode" -->
          <NoteList
            :allow-add="can('edit-company')"
            :extra-params="{ company_id: idComputed }"
          />
        </v-tab-item>
        <v-tab-item value="documents" class="pa-4">
          <!-- TODO: the notes should be separate based on "mode" -->
          <DocumentList
            :allow-add="can('edit-company')"
            :extra-params="{ company_id: idComputed }"
          />
        </v-tab-item>
        <v-tab-item value="other" class="pa-4">
          <v-form @submit.prevent="handleFormOthersSubmit" ref="formOthers">
            <v-row dense>
              <!-- <v-col sm="12">
                <v-select
                  class="w-50"
                  :items="accountingRangeOptions"
                  :item-text="(item) => $t(item.translation_key)"
                  item-value="id"
                  label="Hesap Hareketleri Tarih Aralığı"
                  v-model="formDataOthers.accouting_range_id"
                  :readonly="disabled"
                  :filled="disabled"
                  hide-details="auto"
                ></v-select>
              </v-col> -->
              <v-col sm="6">
                <v-checkbox
                  v-model="formDataOthers.cash_payment_notification"
                  :label="$t('labels.cash_payment_notification')"
                  :readonly="disabled"
                  :filled="disabled"
                  hide-details="auto"
                ></v-checkbox>
              </v-col>
              <v-col sm="6">
                <v-checkbox
                  v-model="formDataOthers.cash_collection_notification"
                  :label="$t('labels.cash_collection_notification')"
                  :readonly="disabled"
                  :filled="disabled"
                  hide-details="auto"
                ></v-checkbox>
              </v-col>
              <!-- <v-col sm="6">
                <v-checkbox
                  v-model="formDataOthers.has_module_lawyer"
                  :label="$t('labels.has_module_lawyer')"
                  :readonly="disabled"
                  :filled="disabled"
                  hide-details="auto"
                ></v-checkbox>
              </v-col> -->
              <!-- <v-col sm="6">
                <v-checkbox
                  v-model="formDataOthers.has_module_accounting"
                  :label="$t('labels.has_module_accounting')"
                  :readonly="disabled"
                  :filled="disabled"
                  hide-details="auto"
                ></v-checkbox>
              </v-col> -->
            </v-row>

            <rs-form-buttons
              :hide-cancel="mode === 'myCompany'"
              :hide-save-close="mode === 'myCompany'"
              :is-loading="isLoading"
              @cancel="handleCancelClick"
              @submit="handleFormOthersSubmit"
              v-if="!disabled"
            />
          </v-form>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <v-card-text v-else-if="mode === 'myCompany' && !idComputed">
      Düzenleme yapabilmek için üst menüden yönetim şirketi seçin.
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

// import { ACCOUNTING_RANGES } from "@/core/data/accounting-ranges.js";
import ActionLogList from "@/view/content/action-logs/ActionLogList";
import { hasForm, hasPermissions } from "@/view/mixins";
import CompanyWorkerList from "@/view/content/CompanyWorkerList";
import NoteList from "@/view/content/Notes/NoteList";
import DocumentList from "@/view/content/documents/DocumentList";
import ProviderList from "@/view/content/companies/ProviderList";
import CompanyClusterList from "./CompanyClusterList";

export default {
  name: "CompanyForm",
  mixins: [hasForm, hasPermissions],
  components: {
    ActionLogList,
    CompanyClusterList,
    CompanyWorkerList,
    DocumentList,
    NoteList,
    ProviderList,
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    mode: {
      type: String,
      required: false,
      default: "admin",
    },
  },
  computed: {
    ...mapGetters(["companyId"]),
    idComputed() {
      return this.id === -1 ? this.companyId : this.id;
    },
  },
  watch: {
    idComputed() {
      this.load();
    },
  },
  mounted() {
    if (this.idComputed) {
      this.load();
    }
  },
  data() {
    return {
      selectedTab: "info",
      formData: {
        title: null,
        name: null,
        address: null,
        address_legal: null,
        city_id: null,
        district_id: null,
        tax_office: null,
        tax_number: null,
        // owner_id: null,
        website: null,
        phone: null,
        fax: null,
        company_type_id: null,
        owner_name: null,
        owner_email: null,
        owner_phone: null,
        email: null,
        logo: null,
        is_active: true,
      },
      formDataOthers: {
        // accouting_range_id: null,
        cash_payment_notification: false,
        cash_collection_notification: false,
        // has_module_lawyer: false,
        // has_module_accounting: false,
      },
      // accountingRangeOptions: ACCOUNTING_RANGES,
      companyUsersFormData: [],
    };
  },
  methods: {
    /**
     * Retrieves company data
     * @return {Promise} Resolves if data is retrieved
     */
    load() {
      if (!this.idComputed || this.isLoading) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .query(`companies/${this.idComputed}`)
        .then((response) => {
          this.originalData = Object.assign({}, response.data.data);

          for (const index in this.formData) {
            if (response.data.data[index] !== undefined) {
              this.formData[index] = response.data.data[index];
            }
          }

          for (const index in this.formDataOthers) {
            if (response.data.data[index] !== undefined) {
              this.formDataOthers[index] = response.data.data[index];
            }
          }

          this.companyUsersFormData = response.data.data.users;
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormOthersSubmit(event) {
      if (this.isLoading || !this.$refs.formOthers.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      return this.handleSubmit(event, this.formDataOthers);
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      return this.handleSubmit(event, this.formData);
    },
    handleSubmit(event, formData) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;
      let config = {};

      if (formData.phone) {
        formData.phone = this.formData.phone.replace(/[^0-9+]/g, "");
      }

      if (formData.owner_phone) {
        formData.owner_phone = this.formData.owner_phone.replace(
          /[^0-9+]/g,
          ""
        );
      }

      if (formData.fax) {
        formData.fax = this.formData.fax.replace(/[^0-9+]/g, "");
      }

      let data = Object.assign({}, formData);

      if (this.formData.new_logo) {
        config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        data = new FormData();

        for (const index in formData) {
          if (formData[index] === undefined || formData[index] === null) {
            data.append(index, "");
          } else if (formData[index].constructor.name === "Boolean") {
            data.append(index, formData[index] ? "1" : "0");
          } else {
            data.append(index, formData[index]);
          }
        }
      }

      if (this.idComputed) {
        if (data.constructor.name !== "FormData") {
          data._method = "put";
        } else {
          data.append("_method", "put");
        }

        this.$api
          .post(`companies/${this.idComputed}`, data, config)
          .then(() => {
            this.$toast.success("Kaydedildi");
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.$nextTick(() => {
              this.load();
            });
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post("companies", data, config)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.$router.replace({
              name: "definitions.companies.edit",
              params: { id: response.data.data.id },
            });
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleCancelClick() {
      this.$router.back();
    },
    handleLogoChange(file) {
      if (!file) {
        this.formData.new_logo = null;
        return;
      }

      const allowedMimes = ["image/jpg", "image/jpeg", "image/png"];

      if (allowedMimes.indexOf(file.type) === -1) {
        this.formData.new_logo = null;
        this.$toast.error("Sadece PNG veya JPG yükleyebilirsiniz.");
        return;
      }

      this.formData.new_logo = file;
    },
    handleRemoveLogoClick() {
      this.formData.new_logo = null;
      this.formData.logo = null;
    },
    showActionLogs() {
      this.$refs.actionLogList.show("App\\Models\\Company", this.idComputed);
    },
  },
};
</script>

<style lang="scss">
.v-input--checkbox {
  label {
    margin-bottom: 0;
  }
}

.report-logo {
  max-height: 120px;
  max-width: 300px;
}
</style>
